export const seoConfig = {
  home: {
    title: "Construction Equipment Rental in Pune - Equip9",
    description:
      "Equip9, a leading name in construction equipment rentals, offers one of the largest rental fleets in the industry, providing top-quality machinery for every project need.",
    keywords: [
      "Construction Equipment Rentals",
      "Heavy Equipment Rental Platform",
      "Construction Equipment Fleet",
      "Equipment Rental App",
      "Used Construction Equipment",
      "All-in-One Equipment Platform",
      "Construction Machinery Rentals",
      "Spare Parts for Construction Equipment",
      "E-Learning for Equipment Operators",
      "Mechanic and Operator Network",
      "Rent Construction Equipment",
      "Reliable Rental Equipment",
      "Fleet Rental Solutions",
      "Heavy Machinery Marketplace",
      "Equipment Rentals and Spare Parts",
    ].join(", "),
  },
  about: {
    title: "About Equip9 | Rent Construction Equipment",
    description:
      "Learn about Equip9, Pune's leading provider of construction equipment rentals. From quality machines to excellent service, we power your projects with ease.",
  },
  ourProduct: {
    title: "Equip9 Products: Your Reliable Construction Equipment Partner",
    description:
      "Equip9 offers a variety of construction equipment for rent in Pune. Explore high-quality tools and machinery designed for efficiency and project success.",
  },
  ourBlog: {
    title: "Insights and Tips: Equip9's Construction Equipment Blogs",
    description:
      "Stay updated with Equip9's blog on construction equipment rental trends, industry tips, and expert advice. Perfect for Pune's builders and project managers.",
  },
  contact: {
    title: "Get in Touch with Equip9 - Construction Equipment Experts",
    description:
      "Reach out to Equip9 for expert guidance on construction equipment rental in Pune. We're here to answer your queries and assist with your project needs.",
  },
  termsAndConditions: {
    title: "Equip9 Terms & Conditions - Rental Services & User Guidelines",
    description:
      "Review Equip9's Terms & Conditions for equipment rental services in Pune. Understand the rules, responsibilities, and guidelines for using our platform and services.",
  },
  privacyPolicy: {
    title: "Equip9 Privacy Policy - Secure Rental Services in Pune",
    description:
      "Read Equip9's Privacy Policy to understand how we protect your personal data when using our equipment rental services. Learn about data collection, storage, and security practices.",
  },
  refundPolicy: {
    title: "Equip9 Refund Policy - Easy & Transparent Rental Returns in Pune",
    description:
      "Learn about Equip9’s clear refund policy for equipment rentals. Find out about our refund process, eligibility, and how we ensure a hassle-free experience.",
  },
  ratingSystem: {
    title: "Equip9 Rating System - Transparent Reviews & Customer Feedback",
    description:
      "Explore Equip9’s rating system, designed to help customers share their experiences with our equipment rentals. Learn how ratings guide your rental decisions.",
  },
  cookiesNotice: {
    title: "Equip9 Cookies Notice - Transparent Cookie Usage & Policy",
    description:
      "Learn how Equip9 uses cookies to enhance your experience. Our Cookies Notice outlines how we collect and use data on our website for better services and functionality.",
  },
};
