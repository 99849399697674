import React from 'react'
import { WebPageLayout } from '../../Layout/WebpageLayout';
import { Helmet } from 'react-helmet';
// import { GET_ALL_MEMBERS_IMAGE_LINKS, IS_MEMEBER_FEMALE } from '../../helpers/getS3Images';
import "./OurProduct.css"
import { ProductCarousel } from './ProductCarousel';
import { Productbanner } from './ProductBanner';
import { ourProduct } from '../../../staticData/staticData';
import "../Home/Home.css"
import { Flex } from '@chakra-ui/layout';
import { MobileResponsiveFooter } from '../../components/MobileResposiveFooter/MobileResponsiveFooter';
import product from '../../../assets/logo/our-product-banner-cut.png'
import sitemap from './seo/Product.xml';
import GoogleTagManager from '../../components/GoogleTagManager';
import MetaTags from '../../components/MetaTags/MetaTags';
import { seoConfig } from '../../components/MetaTags/seoConfig';

export const OurProduct = () => {
  const { title, description } = seoConfig.ourProduct;

  return (
    <>

      <div>
         {/* Injected Google Tag Manager */}
         <GoogleTagManager />

          {/* seo meta tags */}
          <MetaTags title={title} description={description} />
         
        <WebPageLayout>
          <div className='banner-hero-section-product'>
            <ProductCarousel
              array={[
                `${process.env.REACT_APP_CDN_STATIC_URL}Website/Product/ourProductHero.png`,
              ]}
            />
          </div>
          <div className='seperator-product'>

          </div>

          <div className='product-item'>

            {ourProduct.map(product => (
              <Productbanner
                key={product.id}
                type = {product?.type}
                headingText={product.headingText}
                highlightedText={product.highlightedText}
                description={product.description}
                bannerImage={product.bannerImage}
                marketplaceLink={product.marketplaceLink}
              />
            ))}
          </div>
        </WebPageLayout>
      </div>

    </>


  );
};


export default OurProduct