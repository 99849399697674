import { VideoContainer } from "../../components/VideoContainer/VideoContainer";
import Banner1 from "../../../assets/banner-images/Banner-1.png";
import Banner2 from "../../../assets/banner-images/Banner-2.png";
import Banner3 from "../../../assets/banner-images/Banner-3.png";
import HightLightSection1Image1 from "../../../assets/highlighted-elements/section-1/image-1.png";
import HightLightSection1Image2 from "../../../assets/highlighted-elements/section-1/image-2.png";
import HightLightSection1Image3 from "../../../assets/highlighted-elements/section-1/image-3.png";
import HightLightSection2Image1 from "../../../assets/highlighted-elements/section-2/image-1.png";
import HightLightSection2Image2 from "../../../assets/highlighted-elements/section-2/image-2.png";
import HightLightSection2Image3 from "../../../assets/highlighted-elements/section-2/image-3.png";
import HightLightSection3Image1 from "../../../assets/highlighted-elements/section-3/image-1.png";
import HightLightSection3Image2 from "../../../assets/highlighted-elements/section-3/image-2.png";
import HightLightSection3Image3 from "../../../assets/highlighted-elements/section-3/image-3.png";
import { IMAGE_CONSTANTS } from "../../constants/imageConstants";
import { Banner } from "../../components/Banner/Banner";
import { Showcase } from "../../components/Showcase/Showcase";
import { WebPageLayout } from "../../Layout/WebpageLayout";
import  React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setSelectedCountryThunk } from "../../../webapp/features/global/appConfig/thunk/setSelectedCountryThunk";
import { Helmet } from "react-helmet";
import "../Home/Home.css";
import { ReactSVG } from "react-svg";
import { useNavigate } from "react-router-dom";
import { searchOperatorBlock,ConnectDealerBlock,SerachSkilled, defaultEnglishJson } from "../../../staticData/staticData";
import {
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from '@chakra-ui/react';

import { MobileResponsiveFooter } from "../../components/MobileResposiveFooter/MobileResponsiveFooter";
import { MobileHeader } from "../../components/MobileHeader/MobileHeader";
import { getAllLanguageJsonThunk } from "../../../webapp/features/global/mtData/thunk/getAllLanguagesThunk";
import { setLanguage } from "../../../webapp/features/global/appConfig/slice/appConfigSlice";
import languageConstant from "../../../constants/languageConstant";
import homesitemap from "./seo/Home.xml";
import GoogleTagManager from "../../components/GoogleTagManager";
import MetaTags from "../../components/MetaTags/MetaTags";
import { seoConfig } from "../../components/MetaTags/seoConfig";

export const Home = () => {
  const dispatch = useDispatch();
  const { title, description,keywords } = seoConfig.home;

  useEffect(() => {
    dispatch(setSelectedCountryThunk("91"));
      dispatch(getAllLanguageJsonThunk("english.json"));
      dispatch(setLanguage(defaultEnglishJson));
      dispatch(setLanguage(defaultEnglishJson))
  }, []);


  return (
    <>

        {/* Injected Google Tag Manager */}
        <GoogleTagManager />

        {/* seo meta tags */}
        <MetaTags title={title} description={description} keywords={keywords} />

        <WebPageLayout>
         
          <VideoContainer
            height={"600px"}
            width={"100vw"}
            videoUrl={`${process.env.REACT_APP_CDN_STATIC_URL}${IMAGE_CONSTANTS.HERO_SECTION_VIDEO_LINK}`}
            type="video/mp4"
            heading="ONE INDUSTRY | ONE APP"
            subHeading="Operators | Rentals | Mechanics | Used Equipment | E-Learning | Dealers | Financiers | Spare Parts | Scrapping"
            message="Join the Heavy Equipment Revolution Today"
          />

          {/* SECTION */}
          <div
            style={{
              backgroundColor: "var(--dark-50)",
              color: "var(--light-10)",
            }}
          >
            <Banner
              headingText="The Heavy Equipment"
              highlightedText="Industry"
              description="Operators | Rentals | Mechanics | Used Equipment | E-Learning | Dealers | Financiers | Spare Parts | Scrapping"
              bannerImage={Banner1}
            />

            <Showcase
              heading="SEARCH YOUR NEXT OPERATOR"
              subheading="Certified Workforce Management System"
              description="Search Operator | Post Free Job | Verified Documents"
              mainImage={`${process.env.REACT_APP_CDN_STATIC_URL}${IMAGE_CONSTANTS.OPERATORS_MARKETPLACE_SCREEN}`}
              highlightAreaArray={[
                {
                  image: HightLightSection1Image1,
                  stats: "20000+",
                  category: "Operators Registered",
                },
                {
                  image: HightLightSection1Image2,
                  stats: "27+",
                  category: "Equipment Type",
                },
                {
                  image: HightLightSection1Image3,
                  stats: "10",
                  category: "Free Search",
                },
              ]}
            />
          </div>
          {/* SECTION */}
          <div
            style={{
              backgroundColor: "var(--dark-50)",
              color: "var(--light-10)",
            }}
          >
            <Banner
              headingText="AGGREGATED DIGITAL"
              highlightedText="MARKETPLACE"
              description="AI Powered | Mobile | Web | Global Connect"
              bannerImage={Banner2}
            />
            <Showcase
              heading="CONNECT WITH  OWNERS, DEALERS, & OEMs"
              subheading="One App for all your Needs"
              description="Fastest Connect | Verified Equipment Documents | Qualified Leads"
              mainImage={`${process.env.REACT_APP_CDN_STATIC_URL}${IMAGE_CONSTANTS.MARKETPLACE_SCREEN}`}
              highlightAreaArray={[
                {
                  image: HightLightSection2Image1,
                  stats: "2000+",
                  category: "Listed Equipments",
                },
                {
                  image: HightLightSection2Image2,
                  stats: "190",
                  category: "Cities",
                },
                {
                  image: HightLightSection2Image3,
                  stats: "5000+",
                  category: "Business Owners",
                },
              ]}
            />
          </div>
          {/* SECTION */}
          <div
            style={{
              backgroundColor: "var(--dark-50)",
              color: "var(--light-10)",
            }}
          >
            {/* BANNER */}

            <Banner
              headingText="ZERO BROKERAGE"
              highlightedText="ZERO COMMISSION"
              description="Connect With Verified Owners  | Free Equipment Listing | Create Digital Store"
              bannerImage={Banner3}
            />
            <Showcase
              heading="SEARCH SKILLED MECHANICS"
              subheading="Lower the Downtime of your Equipment"
              description="Reliable Maintenance Partner | Quick Connect | Doorstep Service"
              mainImage={`${process.env.REACT_APP_CDN_STATIC_URL}${IMAGE_CONSTANTS.MECHANICS_MARKETPLACE_SCREEN}`}
              highlightAreaArray={[
                {
                  image: HightLightSection3Image1,
                  stats: "3780+",
                  category: "Mechanics Registered",
                },
                {
                  image: HightLightSection3Image2,
                  stats: "50+",
                  category: "Maintenance Agencies",
                },
                {
                  image: HightLightSection3Image3,
                  stats: "10",
                  category: "Free Search",
                },
              ]}
            />
          </div>
        </WebPageLayout>
    </>
  );
};
