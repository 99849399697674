import React from "react";
import { Helmet } from "react-helmet";

const defaultTitle = "Equip9 - Construction Equipment Rentals";
const defaultDescription =
  "Equip9 offers top-notch construction equipment rentals with a vast fleet to meet all your project requirements.";

const MetaTags = ({ title=defaultTitle, description=defaultDescription,keywords }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      {keywords && <meta name="keywords" content={keywords} />}
    </Helmet>
  );
};

export default MetaTags;