import { WebPageLayout } from "../Layout/WebpageLayout";
import "./Terms.css";
import useWindowWidth from "../../sharedComponent/isMobile/CheckMobile";
import { MobileHeader } from "../components/MobileHeader/MobileHeader";
import { MobileResponsiveFooter } from "../components/MobileResposiveFooter/MobileResponsiveFooter";
import { Flex } from "@chakra-ui/react";
import { Helmet } from "react-helmet";
import privacysitemap from "./SeoPrivacy.xml";
import GoogleTagManager from "../components/GoogleTagManager";
import MetaTags from "../components/MetaTags/MetaTags";
import { seoConfig } from "../components/MetaTags/seoConfig";

function PrivacyPolicy() {
  const isMobile=useWindowWidth();
  const { title, description } = seoConfig.privacyPolicy;

 
  const clickHandler = (e) => {
    e.currentTarget.classList.toggle("active");
    const nextElement = e.currentTarget.nextSibling;
    if (nextElement.style.display === "block") {
      nextElement.style.display = "none";
    } else {
      nextElement.style.display = "block";
    }
  };

  return (
  <div>
    {isMobile <768 ? (<>
      <MobileHeader />
        {/* Injected Google Tag Manager */}
        <GoogleTagManager />

      {/* seo meta tags */}
      <MetaTags title={title} description={description} />

      <Flex>
      <div className="heavyEquipment">
        <div className="EquipmentHeadingdivision">
        <h1 className="Terms-heroheading ">CONTRACTUAL RELATIONSHIP</h1>
        <h3>
          <div className="termsRepossive">
           <p style={{marginTop:"12px"}}>
            <p className="termOpratroHeading">SCOPE</p>
            This notice describes how EQUIP9™ and its affiliates collect and use personal data. This notice specifically applies to:
             <p><span className="termOpratroHeading">Operator Partner:</span> individuals who is in job market or receive job notification, including those who receive order requested by another individual.</p>
             <p><span className="termOpratroHeading" >Maintenance Partner:</span> individuals who provide repair and maintenance services for heavy equipment.</p>
             <p><span className="termOpratroHeading">Business Owner:</span> individuals who request for an operator, or maintenance services.</p>
             <p><span className="termOpratroHeading">Equipment Owner:</span> individuals who request for an operator, or maintenance services.</p>
             
             This notice also governs EQUIP9™’s other collections of personal data in connection with EQUIP9™’s services. For example, we may collect the contact information of individuals who use accounts owned by EQUIP9™ for Business customers or of owners or employees of EQUIP9™, or we may collect other personal data in connection with our mapping technology and features.All those subject to this notice are referred to as “users” in this notice.Our data practices are subject to applicable laws in the places in which we operate. This means that we engage in the practices described in this notice in a particular country or region only if permitted under the laws of those places.

DATA CONTROLLER AND TRANSFER EQUIP9™ INTERNET PRIVATE LIMITED (A 304, Gold Ville, Pune) is the data controller for the personal data collected in connection with use of EQUIP9™ 's services anywhere else.EQUIP9™ INTERNET PRIVATE LIMITED is the data controller for the personal data collected in connection with use of EQUIP9™ 's services anywhere else. We process personal data inside India. The personal data of users outside India is transferred on the basis of mechanisms approved under applicable laws.Questions, comments, and complaints about EQUIP9™’s data practices can be submitted https://www.equip9.com/contacts.
           </p>
          </div>
        </h3>
        </div>
        <Flex>
          <div className="EquipmentHeadingdivision">
            <div className="termsRepossive">
            <p >The following data is collected by or on behalf of EQUIP9™:</p>
            </div>
          </div>
      </Flex>
      <Flex>
          <div className="EquipmentHeadingdivision">
            <div className="termsRepossive">
            <ol type="1">
                <li>
                  <p style={{ marginTop: "1.5rem", marginBottom: "1.5rem" }}>
                    <strong>Data provided by users. This includes:</strong>
                  </p>
                  <div className="collapse-container">
                    <button
                      type="button"
                      onClick={clickHandler}
                      className="Mobilecollapsible"
                    >
                      User profile
                    </button>
                    <div className="content">
                      <p>
                        We collect data when users create or update their
                        EQUIP9™ accounts. This may include their name, email,
                        phone number, login name and password, address, profile
                        picture, payment or banking information (including
                        related payment verification information), government
                        identification documents, including operator partner’s
                        license numbers and images, birthdate, signature, and
                        photo. This also includes vehicle or insurance
                        information of operator partners and maintenance
                        partners, and user settings. We may use the photos
                        submitted by operator partners and/or maintenance
                        partners to verify their identities, such as through
                        facial recognition technologies. For more information,
                        please see the section titled “How we use personal
                        data.”
                      </p>
                    </div>
                    <button
                      type="button"
                      onClick={clickHandler}
                      className="Mobilecollapsible"
                    >
                      Background verification
                    </button>
                    <div className="content">
                      <p>
                        We collect background check and identity verification
                        information for operator partners and maintenance
                        partners. This may include information such as operator
                        partner history or criminal record (where permitted by
                        law), and right to work. This information may be
                        collected by an authorized vendor on EQUIP9™’s behalf.
                        We also collect identity verification from EQUIP9™ Eats
                        users who request alcohol maintenance.{" "}
                      </p>
                    </div>
                    <button
                      type="button"
                      onClick={clickHandler}
                      className="Mobilecollapsible"
                    >
                      Demographic data
                    </button>
                    <div className="content">
                      <p>
                        We may collect demographic data about users, including
                        through user surveys. In some countries, we may also
                        receive demographic data about users from third parties.{" "}
                      </p>
                    </div>
                    <button
                      type="button"
                      onClick={clickHandler}
                      className="Mobilecollapsible"
                    >
                      User content
                    </button>
                    <div className="content">
                      <p>
                        We collect the information users submit when they
                        contact EQUIP9™ customer support, provide ratings or
                        compliments for other users or training institutes or
                        equipment dealers, or otherwise contact EQUIP9™. This
                        may include feedback, photographs or other recordings
                        collected by users.
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <p style={{ marginTop: "1.5rem", marginBottom: "1.5rem" }}>
                    <strong>
                      Data created during use of our services. This includes:
                    </strong>
                  </p>
                  <div className="collapse-container">
                    <button
                      type="button"
                      onClick={clickHandler}
                      className="Mobilecollapsible"
                    >
                      Location data
                    </button>
                    <div className="content">
                      <p>
                        We collect precise or approximate location data from a
                        user’s mobile device if enabled by the user to do so.
                        For operator partners, and maintenance partners, EQUIP9™
                        collects this data when the EQUIP9™ app is running in
                        the foreground (app open and on-screen) or background
                        (app open but not on-screen) of their mobile device. For
                        Business owners, and Equipment owners. EQUIP9™ collects
                        this data when the EQUIP9™ app is running in the
                        foreground. In certain regions (which do not include the
                        European Union), EQUIP9™ may also collect this data when
                        the EQUIP9™ app is running in the background of the
                        user’s mobile device. Operator partners, and Maintenance
                        partners, may use the EQUIP9™ apps without enabling
                        EQUIP9™ to collect location data from their mobile
                        devices. However, this may affect some functionality
                        available in the EQUIP9™ apps. For example, a user who
                        has not enabled location data collection will have to
                        manually search jobs on app . In addition, the location
                        data collected from an operator or maintenance partner
                        device during a work will be updated under settings of
                        individual user accounts, even if they have not enabled
                        location data to be collected from their device,
                        including for purposes of receipt generation, customer
                        support, fraud detection, insurance, and litigation.
                      </p>
                    </div>
                    <button
                      type="button"
                      onClick={clickHandler}
                      className="Mobilecollapsible"
                    >
                      Transaction information
                    </button>
                    <div className="content">
                      <p>
                        We collect transaction information related to the use of
                        our services, including the type of services requested
                        or provided, order details, reachout at work location,
                        date and time the service was provided, amount charged,
                        and payment method. Additionally, if someone uses your
                        promotion code, we may associate your name with that
                        person.{" "}
                      </p>
                    </div>
                    <button
                      type="button"
                      onClick={clickHandler}
                      className="Mobilecollapsible"
                    >
                      Usage data
                    </button>
                    <div className="content">
                      <p>
                        We collect data about how users interact with our
                        services. This includes data such as access dates and
                        times, app features or pages viewed, app crashes and
                        other system activity, type of browser, and third-party
                        sites or services used before interacting with our
                        services. In some cases, we collect this data through
                        cookies, pixels, tags, and similar tracking technologies
                        that create and maintain unique identifiers. To learn
                        more about these technologies, please see our Cookie
                        Notice.{" "}
                      </p>
                    </div>
                    <button
                      type="button"
                      onClick={clickHandler}
                      className="Mobilecollapsible"
                    >
                      Communication data
                    </button>
                    <div className="content">
                      <p>
                        We enable users to communicate with each other and
                        EQUIP9™ through EQUIP9™’s mobile apps and websites. For
                        example, we enable operator partners and business
                        owners, and maintenance partners to call, text, or send
                        other files to each other outside the app (generally
                        disclosing their telephone numbers after payments to
                        each other). To provide this service, EQUIP9™ receives
                        some data regarding the calls, texts, or other
                        communications, including the date and time of the
                        communications and the content of the communications.
                        EQUIP9™ may also use this data for customer support
                        services (including to resolve disputes between users),
                        for safety and security purposes, to improve our
                        products and services, and for analytics.{" "}
                      </p>
                    </div>
                    <button
                      type="button"
                      onClick={clickHandler}
                      className="Mobilecollapsible"
                    >
                      Rental device data
                    </button>
                    <div className="content">
                      <p>We are working on it.</p>
                    </div>
                  </div>
                </li>
              </ol>
            </div>
            <br/><br/>
            {/* <Flex>
              <div className="EquipmentHeadingdivision">
                <p  className="termOpratroHeading">contact@equip9.com</p>
              </div>
            </Flex> */}
          </div>
      </Flex>
      </div>
      </Flex>
     
      <MobileResponsiveFooter />
    </>):(<>
      <WebPageLayout>
          {/* Injected Google Tag Manager */}
          <GoogleTagManager />

          {/* seo meta tags */}
      <MetaTags title={title} description={description} />
      
      <div className="terms">
        <h2 className="terms-heading">Privacy Policy</h2>

        <div className="terms-content">
          <h3 className="rating-h3">Overview</h3>

          <ol type="A">
            <li>
              <strong>SCOPE</strong>
              <p>
                This notice describes how EQUIP9™ and its affiliates collect and
                use personal data. This notice specifically applies to:
              </p>
              <ol type="1">
                <li>
                  <strong>Operator Partner:</strong> individuals who is in job
                  market or receive job notification, including those who
                  receive order requested by another individual.
                </li>
                <li>
                  <strong>Maintenance Partner:</strong> individuals who provide
                  repair and maintenance services for heavy equipment.
                </li>
                <li>
                  <strong>Business Owner:</strong> individuals who request for
                  an operator, or maintenance services.
                </li>
                <li>
                  <strong>Equipment Owner:</strong> individuals who request for
                  an operator, or maintenance services.
                </li>
              </ol>
              <p>
                This notice also governs EQUIP9™’s other collections of personal
                data in connection with EQUIP9™’s services. For example, we may
                collect the contact information of individuals who use accounts
                owned by EQUIP9™ for Business customers or of owners or
                employees of EQUIP9™, or we may collect other personal data in
                connection with our mapping technology and features.All those
                subject to this notice are referred to as “users” in this
                notice.Our data practices are subject to applicable laws in the
                places in which we operate. This means that we engage in the
                practices described in this notice in a particular country or
                region only if permitted under the laws of those places.
              </p>
            </li>
            <li>DATA CONTROLLER AND TRANSFER</li>
            <p>
              EQUIP9™ INTERNET PRIVATE LIMITED (A 304, Gold Ville, Pune) is the
              data controller for the personal data collected in connection with
              use of EQUIP9™ 's services anywhere else.EQUIP9™ INTERNET PRIVATE
              LIMITED is the data controller for the personal data collected in
              connection with use of EQUIP9™ 's services anywhere else. We
              process personal data inside India. The personal data of users
              outside India is transferred on the basis of mechanisms approved
              under applicable laws.Questions, comments, and complaints about
              EQUIP9™’s data practices can be submitted
              https://www.equip9.com/contacts.
            </p>
          </ol>
        </div>

        <div className="terms-content">
          <h3 className="rating-h3">DATA COLLECTION AND USES</h3>
          <ol type="A">
            <li>
              <strong>THE DATA WE COLLECT</strong>
              <p>EQUIP9™ collects:</p>
              <ol type="1">
                <li>
                  Data provided by users to EQUIP9™, such as during account
                  creation
                </li>
                <li>
                  Data created during use of our services, such as location, app
                  usage, and device data
                </li>
                <li>
                  Data from other sources, such as EQUIP9™ partners and third
                  parties that use EQUIP9™ APIs
                </li>
              </ol>

              <p style={{ marginTop: "3rem" }}>
                The following data is collected by or on behalf of EQUIP9™:
              </p>

              <ol type="1">
                <li>
                  <p style={{ marginTop: "1.5rem", marginBottom: "1.5rem" }}>
                    <strong>Data provided by users. This includes:</strong>
                  </p>
                  <div className="collapse-container">
                    <button
                      type="button"
                      onClick={clickHandler}
                      className="collapsible"
                    >
                      User profile
                    </button>
                    <div className="content">
                      <p>
                        We collect data when users create or update their
                        EQUIP9™ accounts. This may include their name, email,
                        phone number, login name and password, address, profile
                        picture, payment or banking information (including
                        related payment verification information), government
                        identification documents, including operator partner’s
                        license numbers and images, birthdate, signature, and
                        photo. This also includes vehicle or insurance
                        information of operator partners and maintenance
                        partners, and user settings. We may use the photos
                        submitted by operator partners and/or maintenance
                        partners to verify their identities, such as through
                        facial recognition technologies. For more information,
                        please see the section titled “How we use personal
                        data.”
                      </p>
                    </div>
                    <button
                      type="button"
                      onClick={clickHandler}
                      className="collapsible"
                    >
                      Background verification
                    </button>
                    <div className="content">
                      <p>
                        We collect background check and identity verification
                        information for operator partners and maintenance
                        partners. This may include information such as operator
                        partner history or criminal record (where permitted by
                        law), and right to work. This information may be
                        collected by an authorized vendor on EQUIP9™’s behalf.
                        We also collect identity verification from EQUIP9™ Eats
                        users who request alcohol maintenance.{" "}
                      </p>
                    </div>
                    <button
                      type="button"
                      onClick={clickHandler}
                      className="collapsible"
                    >
                      Demographic data
                    </button>
                    <div className="content">
                      <p>
                        We may collect demographic data about users, including
                        through user surveys. In some countries, we may also
                        receive demographic data about users from third parties.{" "}
                      </p>
                    </div>
                    <button
                      type="button"
                      onClick={clickHandler}
                      className="collapsible"
                    >
                      User content
                    </button>
                    <div className="content">
                      <p>
                        We collect the information users submit when they
                        contact EQUIP9™ customer support, provide ratings or
                        compliments for other users or training institutes or
                        equipment dealers, or otherwise contact EQUIP9™. This
                        may include feedback, photographs or other recordings
                        collected by users.
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <p style={{ marginTop: "1.5rem", marginBottom: "1.5rem" }}>
                    <strong>
                      Data created during use of our services. This includes:
                    </strong>
                  </p>
                  <div className="collapse-container">
                    <button
                      type="button"
                      onClick={clickHandler}
                      className="collapsible"
                    >
                      Location data
                    </button>
                    <div className="content">
                      <p>
                        We collect precise or approximate location data from a
                        user’s mobile device if enabled by the user to do so.
                        For operator partners, and maintenance partners, EQUIP9™
                        collects this data when the EQUIP9™ app is running in
                        the foreground (app open and on-screen) or background
                        (app open but not on-screen) of their mobile device. For
                        Business owners, and Equipment owners. EQUIP9™ collects
                        this data when the EQUIP9™ app is running in the
                        foreground. In certain regions (which do not include the
                        European Union), EQUIP9™ may also collect this data when
                        the EQUIP9™ app is running in the background of the
                        user’s mobile device. Operator partners, and Maintenance
                        partners, may use the EQUIP9™ apps without enabling
                        EQUIP9™ to collect location data from their mobile
                        devices. However, this may affect some functionality
                        available in the EQUIP9™ apps. For example, a user who
                        has not enabled location data collection will have to
                        manually search jobs on app . In addition, the location
                        data collected from an operator or maintenance partner
                        device during a work will be updated under settings of
                        individual user accounts, even if they have not enabled
                        location data to be collected from their device,
                        including for purposes of receipt generation, customer
                        support, fraud detection, insurance, and litigation.
                      </p>
                    </div>
                    <button
                      type="button"
                      onClick={clickHandler}
                      className="collapsible"
                    >
                      Transaction information
                    </button>
                    <div className="content">
                      <p>
                        We collect transaction information related to the use of
                        our services, including the type of services requested
                        or provided, order details, reachout at work location,
                        date and time the service was provided, amount charged,
                        and payment method. Additionally, if someone uses your
                        promotion code, we may associate your name with that
                        person.{" "}
                      </p>
                    </div>
                    <button
                      type="button"
                      onClick={clickHandler}
                      className="collapsible"
                    >
                      Usage data
                    </button>
                    <div className="content">
                      <p>
                        We collect data about how users interact with our
                        services. This includes data such as access dates and
                        times, app features or pages viewed, app crashes and
                        other system activity, type of browser, and third-party
                        sites or services used before interacting with our
                        services. In some cases, we collect this data through
                        cookies, pixels, tags, and similar tracking technologies
                        that create and maintain unique identifiers. To learn
                        more about these technologies, please see our Cookie
                        Notice.{" "}
                      </p>
                    </div>
                    <button
                      type="button"
                      onClick={clickHandler}
                      className="collapsible"
                    >
                      Communication data
                    </button>
                    <div className="content">
                      <p>
                        We enable users to communicate with each other and
                        EQUIP9™ through EQUIP9™’s mobile apps and websites. For
                        example, we enable operator partners and business
                        owners, and maintenance partners to call, text, or send
                        other files to each other outside the app (generally
                        disclosing their telephone numbers after payments to
                        each other). To provide this service, EQUIP9™ receives
                        some data regarding the calls, texts, or other
                        communications, including the date and time of the
                        communications and the content of the communications.
                        EQUIP9™ may also use this data for customer support
                        services (including to resolve disputes between users),
                        for safety and security purposes, to improve our
                        products and services, and for analytics.{" "}
                      </p>
                    </div>
                    <button
                      type="button"
                      onClick={clickHandler}
                      className="collapsible"
                    >
                      Rental device data
                    </button>
                    <div className="content">
                      <p>We are working on it.</p>
                    </div>
                  </div>
                </li>
              </ol>
            </li>
          </ol>
        </div>

        <div className="terms-link">
          <a href="mailto:contact@equip9.com">contact@equip9.com</a>
        </div>
      </div>
    </WebPageLayout></>)}
  </div>
  );
}

export default PrivacyPolicy;
