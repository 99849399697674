import React from "react";
import "../../components/MobileResposiveFooter/MobileResponsiveFooter.css";
import { ReactSVG } from "react-svg";
import { Link } from "react-router-dom";
import Facebook from "../../../assets/SocialmediaLogo/Facebook.svg";
import Instagram from "../../../assets/SocialmediaLogo/Instagram.svg";
import Linkdin from "../../../assets/SocialmediaLogo/LinkedIn.svg";
import YouTube from "../../../assets/SocialmediaLogo/YouTube.svg";

export const MobileResponsiveFooter = () => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <>
      <div className="FooterHeader">
        <div className="EquipmentHeadingdivision">
          <ReactSVG
            src={
              "https://dev-static.equip9.com/Website/Home/MobileIcon/LogoEquip9.svg"
            }
          />
          <h3 className="FooterHeading">
            <p className="digitalPlateForm">
              To aggregate the heavy equipment industry on a digital platform.
            </p>
            <div></div>
          </h3>
          <div className="maps">
            {/* <iframe
              width="100%"
              height="200px"
              frameBorder="0"
              scrolling="no"
              marginHeight="0"
              marginWidth="0"
              src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=416,%20The%20Address%20Commercia,%20Shankar%20Kalat%20Nagar,%20Wakad,%20Pune,%20Maharashtra%20411057+(EQUIP9%E2%84%A2%EF%B8%8F%20-%20Connecting%20Equipment%20To%20Your%20Business)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
            > */}
            /
            <iframe
              width="100%"
              height="200px"
              frameBorder="0"
              scrolling="no"
              marginHeight="0"
              marginWidth="0"
              src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d60504.12219898742!2d73.67648336605416!3d18.59622347464813!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e6!4m5!1s0x3bc2bbb98e08c485%3A0x88605aadb7c213a6!2sGera&#39;s%20Imperium%20Rise%2C%20Balaji%20Realty%2C%20HINJEWADI%20RAJIV%20GANDHI%20INFOTECH%20PARK-6%2C%20HINJEWADI%20RAJIV%20GANDHI%20INFOTECH%20PARK-3%2C%20Hinjawadi%20Phase%202%20Road%2C%20Hinjawadi%20Phase%20II%2C%20Hinjawadi%20Rajiv%20Gandhi%20Infotech%20Park%2C%20Hinjawadi%2C%20Pune%2C%20Maharashtra!3m2!1d18.5961466!2d73.71768329999999!4m5!1s0x3bc2bbb98e08c485%3A0x88605aadb7c213a6!2sGera&#39;s%20Imperium%20Rise!3m2!1d18.5961466!2d73.71768329999999!5e0!3m2!1sen!2sin!4v1732948274024!5m2!1sen!2sin"
              style="border:0;"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            >
              <a href="https://www.maps.ie/population/">
                Population Estimator map
              </a>
            </iframe>
            <h1 className="Addresshero">
              1121, Gera's Imperium Rise, Hinjewadi Phase II, Pune, Maharashtra
              411057
            </h1>
          </div>
          <div className="pages-suppport">
            <div className="Home">
              <li>
                <Link to="/" className="footer-link" onClick={scrollToTop}>
                  <p className="footerText">Home</p>
                  <title>
                    One Industry | One App: Operators, Rentals,Mechanics, Spare
                    Parts and More
                  </title>
                </Link>
              </li>
              <li>
                <Link to="/about" className="footer-link" onClick={scrollToTop}>
                  <p className="footerText">About Us</p>
                </Link>
              </li>
              <li>
                <Link
                  to="/our-product"
                  className="footer-link"
                  onClick={scrollToTop}
                >
                  <p className="footerText"> Product</p>
                </Link>
              </li>
              <li>
                <Link
                  to="/contact"
                  className="footer-link"
                  onClick={scrollToTop}
                >
                  <p className="footerText">Contact</p>
                </Link>
              </li>
            </div>
            <div>
              <li>
                <Link
                  className="footer-link"
                  to="/terms-and-conditions"
                  onClick={scrollToTop}
                >
                  <p className="footerText">Terms & Conditions</p>
                </Link>
              </li>
              <li>
                <Link
                  className="footer-link"
                  to="/privacy-policy"
                  onClick={scrollToTop}
                >
                  <p className="footerText">Privacy Policy</p>
                </Link>
              </li>
              <li>
                <Link
                  className="footer-link"
                  to="/refund-policy"
                  onClick={scrollToTop}
                >
                  <p className="footerText">Refund Policy</p>
                </Link>
              </li>
            </div>
            <div>
              <li>
                <Link
                  className="footer-link"
                  to="/rating-system"
                  onClick={scrollToTop}
                >
                  <p className="footerText">Rating System</p>
                </Link>
              </li>
              <li>
                <Link
                  className="footer-link"
                  to="/cookies-notice"
                  onClick={scrollToTop}
                >
                  <p className="footerText">Cookies Notice</p>
                </Link>
              </li>
            </div>
          </div>
          <div className="socialMediaIcons">
            <a
              href="https://www.facebook.com/futurebizequip9?mibextid=ZbWKwL"
              target="_blank"
              rel="noopener noreferrer"
            >
              <ReactSVG src={Facebook} />
            </a>

            <a
              href="https://www.instagram.com/equip9_/?igshid=OGQ5ZDc2ODk2ZA%3D%3D"
              target="_blank"
              rel="noopener noreferrer"
            >
              <ReactSVG src={Instagram} />
            </a>
            <a
              href="https://www.youtube.com/results?sp=mAEB&search_query=equip9"
              target="_blank"
              rel="noopener noreferrer"
            >
              <ReactSVG src={YouTube} />
            </a>

            <a
              href="https://www.linkedin.com/company/futurebizequip9/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <ReactSVG src={Linkdin} />
            </a>
          </div>

          <div className="seprator"></div>
          <h3 className="copyRight">
            Copyright © 2023 Equip<span className="footerText">9™</span> | All
            Rights Reserved <br /> GSTIN - 27AAFCE7105E1ZX <br /> CIN:
            U51901PN2019PTC187811
          </h3>
        </div>
      </div>
    </>
  );
};
