import styled from "styled-components";
import { WebPageLayout } from "../../Layout/WebpageLayout";
import HeroImage from "../../../assets/contact-page/contact-page-hero.png";
import { Formik } from "formik";
import * as Yup from "yup";
import { FormikInput } from "../../components/Input/Input";
import { Button, Flex, useToast, Text } from "@chakra-ui/react";
import { REGEX_EMAIL_CHECKER } from "../../../webapp/helpers/regex";
import { API_CONSTANTS } from "../../../constants/apiConstants";
import { convertToFormData } from "../../../webapp/helpers/dataConversion/convertToFormData";
import { Helmet } from "react-helmet";
import "../Home/Home.css";
import React from "react";

import contactsitemap from "./seo/Contact.xml";
import GoogleTagManager from "../../components/GoogleTagManager";
import MetaTags from "../../components/MetaTags/MetaTags";
import { seoConfig } from "../../components/MetaTags/seoConfig";
const BackgroundImageSection = styled.div`
  width: ${({ width }) => (width ? width : "100vw")};
  height: ${({ height }) => (height ? height : "100%")};
  display: ${({ display }) => (display ? display : "block")};
  flex-direction: ${({ direction }) => (direction ? direction : "row")};
  gap: ${({ gap }) => (gap ? gap : "0")};
  padding: ${({ padding }) => (padding ? padding : "0")};
  background-image: ${({ image }) => (image ? `url(${image})` : "black")};
  background-size: ${({ image }) => (image ? "cover" : "")};
  color: var(--light-10);
`;

export const Contact = () => {
  const toast = useToast();
  const { title, description } = seoConfig.contact;


  const postForm = async ({ name, email, phoneNumber, company, purpose }) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/PostContactDetails`,
        {
          method: API_CONSTANTS.HTT_POST,
          body: convertToFormData({
            Name: name,
            Email: email,
            PhoneNumber: parseInt(phoneNumber),
            Company: company,
            YourPurpose: purpose,
          }),
        }
      );
      const data = await response.json();
      if (data.Status === 201) {
        toast({
          status: "success",
          title: "Ticket created successfully.",
          description: `Your ticket id is ${data.Data.ContactUsId}`,
          position: "top-right",
          isClosable: true,
        });
      } else {
        toast({
          status: "error",
          title: "Ticket could not be created.",
          description: "Please try after some time.",
          position: "top-right",
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        status: "error",
        title: "Ticket could not be created.",
        description: "Please try after some time.",
      });
    }
  };

  return (
    <>
      {/* Injected Google Tag Manager */}
      <GoogleTagManager />

      {/* seo meta tags */}
      <MetaTags title={title} description={description} />

      <WebPageLayout>
        
        <BackgroundImageSection
          image={HeroImage}
          display="flex"
          padding="7.5em 6.25em"
        >
          <div style={{ maxWidth: "880px" }}>
            <p
              style={{
                fontSize: "56px",
                lineHeight: "73px",
                fontWeight: "700",
                color: "var(--light-10)",
              }}
            >
              <span style={{ color: "var(--primary)" }}>Accelerate</span> your
              business with EQUIP
              <span style={{ color: "var(--primary)" }}>9 &trade;</span> Team.
              Contact us, Today.
            </p>
          </div>
        </BackgroundImageSection>
      
        <BackgroundImageSection image={HeroImage} display="flex">
          <Flex
            style={{ maxWidth: "880px" }}
            padding={[
              "5em 3em",
              "5em 3em",
              "5.5em 5em",
              "5.5em 5em",
              "7.5em 6.25em",
              "7.5em 6.25em",
            ]}
          >
            <Flex direction={"column"} fontWeight={"700"} color={"#FEFEFE"}>
              <Text
                fontSize={["1.5em", "1.5em", "2.25em", "3em", "3.5em", "3.5em"]}
              >
                <span style={{ color: "#F79C00" }}>Accelerate</span> your
                business with EQUIP
                <span style={{ color: "#F79C00" }}>9 &trade;</span> Team.
              </Text>

              <Text
                fontSize={["1.5em", "1.5em", "2.25em", "3em", "3.5em", "3.5em"]}
              >
                Contact us, Today.
              </Text>
            </Flex>
          </Flex>
        </BackgroundImageSection>

        <Flex
          display="flex"
          direction={["column", "column", "column", "column", "row", "row"]}
          bgColor="#1A1E21"
          padding={[
            "37px 50px",
            "37px 80px",
            "37px 80px",
            "37px 100px",
            "37px 100px",
            "37px 121px",
          ]}
          justifyContent="space-between"
          gap={"1em"}
        >
          <Flex direction={"column"} w={"100%"}>
            <Text
              fontSize={["14px", "14px", "15px", "15px", "18px", "18px"]}
              style={{
                fontWeight: 600,
                lineHeight: "23.5px",
                letterSpacing: "1%",
                color: "#FFF",
              }}
            >
              Contact us
            </Text>
            <Text
              fontSize={["24px", "24px", "35px", "40px", "48px", "48px"]}
              style={{
                color: "#F79C00",
                // fontSize: "48px",
                lineHeight: "68px",
                fontWeight: 700,
                marginTop: "1rem",
              }}
            >
              Get in touch today
            </Text>
            <p style={{ marginTop: "0.5rem", color: "#FFF" }}>
              How can we help you grow?
            </p>
            <ul
              style={{
                listStyle: "none",
                display: "flex",
                flexDirection: "column",
                maxWidth: "339px",
                marginTop: "1.5em",
                gap: "1.2em",
              }}
            >
              <li style={{ color: "var(--light-10)" }}>
                <a
                  style={{ textDecoration: "none", color: "var(--light-10)" }}
                  href="mailto:contact@equip9.com"
                >
                  contact@equip9.com
                </a>
                /
                <a
                  style={{ textDecoration: "none", color: "var(--light-10)" }}
                  href="mailto:support@equip9.com"
                >
                  support@equip9.com
                </a>
              </li>

              <li>
                {" "}
                <a
                  style={{ textDecoration: "none", color: "var(--light-10)" }}
                  href="tel:>+917822095128"
                >
                  +91 7822095128
                </a>
              </li>
              <li style={{ color: "var(--light-10)" }}>
                Address : 1121, Gera's Imperium Rise, Hinjewadi Phase II, Pune,
                Maharashtra 411057
              </li>
            </ul>
          </Flex>

          <Flex
            style={{ maxWidth: "616px" }}
            display="flex"
            wrap="wrap"
            bgColor="var(--dark-20)"
            padding="65px 40px"
            borderRadius="25px"
            borderColor="none"
            justifyContent="center"
            alignItems="center"
          >
            <Formik
              initialValues={{
                name: "",
                email: "",
                phoneNumber: "",
                company: "",
                purpose: "",
              }}
              onSubmit={async (values, actions) => {
                actions.setSubmitting(true);
                await postForm(values);
                actions.setSubmitting(false);
                actions.resetForm();
              }}
              validationSchema={Yup.object({
                name: Yup.string().required("Required"),
                email: Yup.string()
                  .required("Required")
                  .matches(REGEX_EMAIL_CHECKER, "Invalid Email"),
                phoneNumber: Yup.string()
                  .required("Required")
                  .matches(/^\d+$/, "Only numeric values are allowed")
                  .max(10, "Maximum 10 characters allowed")
                  .min(10, "Minimum 10 characters allowed"),
                company: Yup.string()
                  .required("Required")
                  .min("2", "Minimum 2 characters allowed")
                  .max("20", "Maximum 20 characters allowed"),
                purpose: Yup.string()
                  .required("Required")
                  .min("20", "Minimum 20 characters allowed")
                  .max("200", "Maximum 200 characters allowed"),
              })}
            >
              {({ handleSubmit, isSubmitting }) => (
                <form onSubmit={handleSubmit}>
                  <Flex flexDirection="column" gap=" 0.75em">
                    <Flex
                      gap="0.5em"
                      direction={[
                        "column",
                        "column",
                        "column",
                        "column",
                        "row",
                        "row",
                      ]}
                    >
                      <FormikInput
                        name="name"
                        placeholder="Eg. John Carter"
                        label="Name"
                        isRequired={true}
                      />
                      <FormikInput
                        name="email"
                        placeholder="Eg: example@equip9.com"
                        label="Email"
                        isRequired={true}
                      />
                    </Flex>

                    <Flex
                      gap="0.5em"
                      direction={[
                        "column",
                        "column",
                        "column",
                        "column",
                        "row",
                        "row",
                      ]}
                    >
                      <FormikInput
                        name="phoneNumber"
                        placeholder="Eg: +919850065138"
                        label="Phone"
                        isRequired={true}
                      />
                      <FormikInput
                        name="company"
                        placeholder="Eg: Equip9 Private Limited"
                        label="Company"
                        isRequired={true}
                      />
                    </Flex>
                    <FormikInput
                      name="purpose"
                      placeholder="Please type your message here ..."
                      label="Your Purpose"
                      isRequired={true}
                      textArea={true}
                    />
                    <Button
                      type="submit"
                      variant="gradient"
                      padding="24px 36px"
                      maxWidth="186px"
                      height="72px"
                      fontWeight="600"
                      fontSize="20px"
                      isLoading={isSubmitting}
                    >
                      Submit
                    </Button>
                  </Flex>
                </form>
              )}
            </Formik>
          </Flex>
        </Flex>
      </WebPageLayout>
    </>
  );
};
