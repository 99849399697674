import { WebPageLayout } from '../../Layout/WebpageLayout';
import { Helmet } from 'react-helmet';
import "..//Product/OurProduct.css"
import BlogFirst from './blogs/BlogFirst';
import BlogSecond from './blogs/BlogSecond';
import BlogThird from './blogs/BlogThird';
import GoogleTagManager from '../../components/GoogleTagManager';
import MetaTags from '../../components/MetaTags/MetaTags';
import { seoConfig } from '../../components/MetaTags/seoConfig';


export const OurBlog = () => {
  const { title, description } = seoConfig.ourBlog;


  return (
    <>
      <WebPageLayout>
         {/* Injected Google Tag Manager */}
         <GoogleTagManager />

        {/* seo meta tags */}
        <MetaTags title={title} description={description} />

        <BlogFirst />
        <BlogSecond />
        <BlogThird />
      </WebPageLayout>

    </>
  );
};


export default OurBlog