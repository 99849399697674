import { AboutMain } from "./AboutMain";
import Pattern from "./Pattern";
import Client from "./Client";
// import Carousel from "./Carousel";
import { Carousel } from "../../components/Carousel/Carousel";
import Sectionfour from "./Sectionfour";
import Members from "./Members";
import { useEffect, useState } from "react";
// import {
//   GET_ALL_MEMBERS_IMAGE_LINKS,
//   IS_MEMEBER_FEMALE,
// } from "../../helpers/getS3Images";
import { WebPageLayout } from "../../Layout/WebpageLayout";
import { Helmet } from "react-helmet";
import "../Home/Home.css";
import { ReactSVG } from "react-svg";
import { Pagination } from "swiper/modules";
import { MobileCarousel } from "../../components/MobileCarosel/MobileCarosel";
import { MobileResponsiveFooter } from "../../components/MobileResposiveFooter/MobileResponsiveFooter";
import { MobileHeader } from "../../components/MobileHeader/MobileHeader";
import { ImageSlider } from "../../components/ImageSlider/ImageSlider";
import aboutsitemap from "./seo/About.xml";
import GoogleTagManager from "../../components/GoogleTagManager";
import MetaTags from "../../components/MetaTags/MetaTags";
import { seoConfig } from "../../components/MetaTags/seoConfig";

export const About = () => {

  const { title, description } = seoConfig.about;


  // useEffect(() => {
  //   (async () => {
  //     const members = await GET_ALL_MEMBERS_IMAGE_LINKS();
  //     const arrangedMembers = members.reduce(
  //       (allMembers, currentMember) => {
  //         if (IS_MEMEBER_FEMALE(currentMember)) {
  //           allMembers.females.push(currentMember);
  //         } else {
  //           allMembers.males.push(currentMember);
  //         }
  //         return allMembers;
  //       },
  //       { females: [], males: [] }
  //     );
  //     setMemberImages([...arrangedMembers.females, ...arrangedMembers.males]);
  //   })();
  // }, []);

  return (
    <>
    
        {/* Injected Google Tag Manager */}
        <GoogleTagManager />

          {/* seo meta tags */}
          <MetaTags title={title} description={description} />
    
          <WebPageLayout>
            
            <Carousel
              array={[
                `${process.env.REACT_APP_CDN_STATIC_URL}Website/About/HeroCarousel/image3.png`,
                `${process.env.REACT_APP_CDN_STATIC_URL}Website/About/HeroCarousel/image4.png`,
              ]}
            />
            <AboutMain />
            <Pattern />
            <Sectionfour />
            <Client />

            <Members/>
          </WebPageLayout>
      
    </>
  );
};
